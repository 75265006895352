import { navigate } from 'gatsby';

export const isSSG = typeof window === 'undefined';

/**
 * Use this instead of `navigate` to avoid build problems with SSG
 *
 * @param pathname e.g. '/pagename'
 * @param replace pass true for redirect
 */
export const navigateSafe = (pathname: string, replace = false) => {
  !isSSG && navigate(pathname, { replace });
};

// -- Path and Hash Utils --

export const stripTrailingSlash = (path?: string) =>
  path ? path.replace(/\/$/, '') : path;

export const appendSlash = (path?: string) =>
  path ? `${stripTrailingSlash(path)}/` : path;

export const stripHash = (hash?: string) =>
  hash ? hash.replace(/^#/, '') : hash;

export const prependHash = (hash?: string) =>
  hash ? `#${stripHash(hash)}` : hash;

// -- Path Matchers --

export const pathsMatch = (pathA?: string, pathB?: string) =>
  !!pathA && stripTrailingSlash(pathA) === stripTrailingSlash(pathB);

export const hashesMatch = (hashA?: string, hashB?: string) =>
  (!hashA && !hashB) || stripHash(hashA) === stripHash(hashB);

export const pathsMatchWithAnyHash = (
  path?: string,
  currentPath?: string,
  currentHash?: string,
) => !!path && !!currentHash && pathsMatch(path, currentPath);

export const pathsAndHashesMatch = (
  path?: string,
  currentPath?: string,
  hash?: string,
  currentHash?: string,
) => pathsMatch(path, currentPath) && hashesMatch(hash, currentHash);

// -- Generators --

export const makePathWithHash = (path?: string, hash?: string) =>
  path ? appendSlash(path) + (prependHash(hash) ?? '') : path;
