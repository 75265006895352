const confirmDataLayerAndPushEventTag = eventTag => {
  if (window && window.dataLayer) {
    // NOTE: log is for debugging purposes, filter the console output to `event:` for minimal additional noise while testing.
    console.info('eventTag', eventTag);
    window.dataLayer.push(eventTag);
  } else {
    console.error(
      'Error: `dataLayer` not available on the `window` object. Confirm that settings are enabled for this environment.',
    );
  }
};

export function tagVideoPlayProgress({
  videoId = 'Unknown_VideoId',
  videoProgress = '',
}: {
  videoId?: string;
  videoProgress?: string;
}) {
  confirmDataLayerAndPushEventTag({
    event: `Played_${videoId}`,
    // NOTE: videoProgressPercentComplete: 'progress0' | 'progress25' | 'progress50' | 'progress75' | 'progress100'
    videoProgressPercentComplete: videoProgress,
  });
}

export function pushCustomEvent({ tagName, options }) {
  confirmDataLayerAndPushEventTag({
    event: tagName,
    ...options,
  });
}
