import './mobileNav.scss';

import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import ExternalLink from '../externalLink';
import { gsap } from 'gsap';
import { CSSTransition } from 'react-transition-group';

import siteHeaderLogo from '../../images/logo.png';
import shade from '../../images/shade.png';
import shade2 from '../../images/shade2.png';

const MobileNav = ({ onMobileNavClose, navActive }) => {
  useEffect(() => {
    toggleNavActive();
  }, [navActive]);

  let tlNavActive;

  const toggleNavActive = () => {
    if (navActive) {
      if (!tlNavActive) {
        tlNavActive = gsap.timeline({
          onReverseComplete: onNavCloseComplete,
          delay: 0.5,
        });
        tlNavActive.to(
          '.mobile-nav__shade',
          { translateY: 0, duration: 0.25 },
          'bg-in',
        );
        tlNavActive.to(
          '.mobile-nav__shade2',
          { translateY: 0, duration: 0.25, delay: 0.1 },
          'bg-in',
        );
        tlNavActive.to('.mobile-nav__content_container', {
          opacity: 1,
          translateY: 0,
          duration: 0.25,
        });
        tlNavActive.to(
          '.mobile-nav__list_item',
          {
            translateY: 0,
            opacity: 1,
            stagger: {
              each: 0.1,
              repeat: 0,
            },
            duration: 0.5,
          },
          'items-in',
        );
        tlNavActive.to(
          '.mobile-nav__reference_item',
          {
            translateY: 0,
            opacity: 1,
            stagger: {
              each: 0.1,
              repeat: 0,
            },
            duration: 0.5,
            delay: 0.5,
          },
          'items-in',
        );
        tlNavActive.to(
          '.mobile-nav__close_button',
          {
            translateY: 0,
            rotate: 0,
            ease: 'back.out(1.7)',
            duration: 0.3,
          },
          'items-in',
        );
      } else {
        tlNavActive.restart();
      }
    }
  };

  const handleMobileNavClose = () => {
    if (tlNavActive && tlNavActive.reverse) {
      tlNavActive.reverse();
    }
  };

  const onNavCloseComplete = () => {
    onMobileNavClose();
  };

  return (
    <CSSTransition in={navActive} timeout={500} classNames="opacity">
      <div className="mobile-nav__container">
        <div className="mobile-nav__bg"></div>
        <div className="mobile-nav__shade mobile-nav__shades">
          <img src={shade} alt="" className="mobile-nav__shade_img" />
        </div>
        <div className="mobile-nav__shade2 mobile-nav__shades">
          <img src={shade2} alt="" className="mobile-nav__shade_img" />
        </div>
        <div className="mobile-nav__content_container">
          <div className="mobile-nav__content_inner">
            <div className="mobile-nav__logo_container">
              <Link to="/" className="site-header__home_link">
                <img
                  className="site-header__logo"
                  src={siteHeaderLogo}
                  alt="UTUC Mobile Logo"
                />
              </Link>
            </div>
            <nav className="mobile-nav__content" aria-label="mobile">
              <ul className="mobile-nav__list">
                <li className="mobile-nav__list_item" data-id="about">
                  <Link
                    to="/about-the-urinary-tract"
                    className="mobile-nav__link"
                  >
                    About the urinary tract
                  </Link>
                </li>
                <li className="mobile-nav__list_item" data-id="understanding">
                  <Link to="/understanding-utuc" className="mobile-nav__link">
                    Understanding UTUC
                  </Link>
                </li>
                <li className="mobile-nav__list_item" data-id="treatment">
                  <Link to="/treatment-options" className="mobile-nav__link">
                    Getting treated
                  </Link>
                </li>
                <li className="mobile-nav__list_item" data-id="doctor">
                  <Link to="/helpful-resources" className="mobile-nav__link">
                    Resources
                  </Link>
                </li>
              </ul>
              <ul className="mobile-nav__reference_list">
                <li className="mobile-nav__reference_item" data-id="faq">
                  <ExternalLink
                    className="mobile-nav__reference_link"
                    url="https://www.urogen.com/terms-of-use/"
                  >
                    Terms of Use
                  </ExternalLink>
                </li>
                <li className="mobile-nav__reference_item" data-id="faq">
                  <ExternalLink
                    className="mobile-nav__reference_link"
                    url="https://www.urogen.com/privacy-policy/"
                  >
                    Privacy Policy
                  </ExternalLink>
                </li>
              </ul>
            </nav>
          </div>
          <div className="mobile-nav__close_container">
            <button
              className="mobile-nav__close_button"
              onClick={handleMobileNavClose}
            >
              &times;
            </button>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default MobileNav;
