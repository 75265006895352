import React, { FC, ReactNode, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';

type ExternalLinkProps = {
  url?: string;
  className?: string;
  modalContent?: string;
  children?: ReactNode;
  handleBeforeRoute?: () => void;
};

const ExternalLink: FC<ExternalLinkProps> = ({
  url,
  className,
  modalContent = 'Do you still want to continue?',
  children,
  handleBeforeRoute = () => {},
}) => {
  const [linkAcknowledgmentOpen, setLinkAcknowledgmentOpen] = useState(false);

  const handleLinkClick = e => {
    e.preventDefault();
    setLinkAcknowledgmentOpen(true);
  };

  const handleClose = () => {
    setLinkAcknowledgmentOpen(false);
  };

  const handleAcceptance = () => {
    window.open(url, 'windowName', 'noreferrer');
    handleBeforeRoute();
    setLinkAcknowledgmentOpen(false);
  };

  return (
    <>
      <a href={url} onClick={handleLinkClick} className={className}>
        {children}
      </a>
      <Modal
        className="site-modal"
        isOpen={linkAcknowledgmentOpen}
        toggle={handleClose}
      >
        <ModalBody>
          <h3 className="site-modal__header">You’re about to leave UTUC.com</h3>
          <div className="site-modal__content">{modalContent}</div>
          <div className="site-modal__buttons">
            <button className="site-modal__button" onClick={handleAcceptance}>
              Continue
            </button>
            <button className="site-modal__button" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ExternalLink;
