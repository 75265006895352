import { Link } from 'gatsby';
import React from 'react';
import ExternalLink from './externalLink';
import chevronRight from '../images/icon-chevron-right-white.svg';
import facebookLogo from '../images/logo-facebook.svg';
import { isMobile } from 'react-device-detect';
import { pushCustomEvent } from '../utils/tagging';

const Footer = () => {
  return (
    <footer className="site-footer__container">
      <div className="site-footer__inner">
        <nav className="site-footer__nav" aria-label="footer">
          <div className="site-footer__left">
            <ul className="site-footer__nav_list">
              <li className="site-footer__nav_item">
                <ExternalLink
                  url="https://www.urogen.com/terms-of-use/"
                  className="site-footer__nav_link"
                >
                  Terms of Use
                </ExternalLink>
              </li>
              <li className="site-footer__nav_item">
                <ExternalLink
                  url="https://www.urogen.com/privacy-policy/"
                  className="site-footer__nav_link"
                >
                  Privacy Policy
                </ExternalLink>
              </li>
            </ul>
            {!isMobile && (
              <div className="site-footer__legal">
                © 2022 UroGen Pharma, Inc. All rights reserved.
                US-UGN-00023&nbsp;&nbsp;08/22
              </div>
            )}
          </div>
          <div className="site-footer__external_links">
            <a
              href="https://www.facebook.com/uncoveringUTUC/"
              target="_blank"
              rel="noopener noreferrer"
              className="site-footer__link site-footer__nonsurgical_tout"
            >
              <img
                src={facebookLogo}
                alt=""
                className="site-footer__facebook"
              />
              <p>
                Join the <strong>Uncovering UTUC</strong>{' '}
                <span className="break-span--mobile break-span--desktop">
                  <strong>Together</strong> Facebook community
                </span>
              </p>
              <img
                src={chevronRight}
                alt=""
                className="site-footer__nonsurgical_arrow"
              />
            </a>
            <ExternalLink
              url="https://www.jelmyto.com/patient/"
              className="site-footer__link site-footer__nonsurgical_tout site-footer__nonsurgical_tout_intersite"
              modalContent="This link will take you to a website to learn about a prescription treatment option for low-grade UTUC."
              handleBeforeRoute={() =>
                pushCustomEvent({ tagName: 'Jelmyto-link-click', options: {} })
              }
            >
              <p>
                Learn about a nonsurgical{' '}
                <span className="break-span--mobile">
                  treatment{' '}
                  <span className="break-span--desktop">
                    for low-grade UTUC
                  </span>
                </span>
              </p>
              <img
                src={chevronRight}
                alt=""
                className="site-footer__nonsurgical_arrow"
              />
            </ExternalLink>
          </div>
        </nav>
        {isMobile && (
          <div className="site-footer__legal site-footer__legal--mobile">
            © 2022 UroGen Pharma, Inc. All rights reserved.
            US-UGN-00023&nbsp;&nbsp;08/22
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
