import React from 'react';
import { Link } from 'gatsby';
import siteHeaderLogo from '../images/logo.svg';
import tw from 'twin.macro';

const Header = ({ onMobileNavToggle }) => {
  const handleMobileNavToggleClicked = () => {
    onMobileNavToggle();
  };

  return (
    <>
      <header className="site-header">
        {/* if you would like to add irritator here, use classname "site-header__infobar", and set $infobar-height variables */}
        <div className="site-header__container">
          <div className="site-header__inner">
            <Link to="/" className="site-header__home_link">
              <h1>
                <img
                  className="site-header__logo"
                  src={siteHeaderLogo}
                  alt="Upper Tract Urothelial Cancer"
                />
              </h1>
            </Link>
            <nav className="site-header__nav" aria-label="main">
              <ul className="site-header__nav_list">
                <li tw="flex items-center" className="site-header__nav_item">
                  <Link
                    to="/about-the-urinary-tract"
                    className="site-header__nav_link"
                    data-id="about"
                  >
                    About the <span>urinary tract</span>
                  </Link>
                </li>
                <li tw="flex items-center" className="site-header__nav_item">
                  <Link
                    to="/understanding-utuc"
                    className="site-header__nav_link"
                    data-id="understanding"
                  >
                    Understanding <span>UTUC</span>
                  </Link>
                </li>
                <li tw="flex items-center" className="site-header__nav_item">
                  <Link
                    to="/treatment-options"
                    className="site-header__nav_link"
                    data-id="treatment"
                  >
                    Getting <span>treated</span>
                  </Link>
                </li>
                <li tw="flex items-center" className="site-header__nav_item">
                  <Link
                    to="/helpful-resources"
                    className="site-header__nav_link"
                    data-id="doctor"
                  >
                    Resources
                  </Link>
                </li>
              </ul>
            </nav>
            <div
              className="mobile-nav__toggle-container"
              onClick={handleMobileNavToggleClicked}
            >
              <span className="mobile-nav__bar"></span>
              <span className="mobile-nav__bar"></span>
              <span className="mobile-nav__bar"></span>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
